
<template>
    
    <div class="container mx-auto py-0">

        <div class="hidden md:block">
            <video class="w-full z-auto" :src="Video_Header_[$i18n.locale]" :autoplay="true" :poster="Poster_Header_[$i18n.locale]" :controls="true" :muted="true" :loop="true" :playsinline="true" alt="SGB Video"></video>
        </div>
        <div class="md:hidden">
            <video class="w-full z-auto" :src="Video_Header_Mobile_[$i18n.locale]" :autoplay="true" :poster="Poster_Header_Mobile_[$i18n.locale]" :controls="false" :muted="true" :loop="true" :playsinline="true" alt="SGB Video"></video>
        </div>

        <!--
        <swiper
            :slides-per-view="1"
            :space-between="50"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            :class="swiper" 
            :options="swiperOption"
            :navigation="true" :loop="true" :keyboard="true" :pagination="{'clickable': true}"
        >
            <swiper-slide id="slider_1">-->
                <!--<img class="hidden md:block" src="../assets/img/Slider_Elianna.png">-->
                <!--
                <img  class="hidden md:block" :src="Slider_Elianna_[$i18n.locale]" alt="Elianna Slider">
                <img class="md:hidden" :src="Slider_Mobile_Elianna_[$i18n.locale]" alt="Elianna Slider">
                <div class="absolute bottom-10 items-center left-0 right-0">
                    <Button @click="openModal_1" class="bg-sgbred hover:bg-sgbdarkred py-2 px-2 md:w-300 w-60 border border-white hover:border-transparent rounded-full"><span class="text-white md:text-xl text-base font-bold">{{ $t("header.sl-btn")}}</span></Button>
                    <Dialog id="Elianna" class="w-max md:w-1/2 md:p-0 p-3" v-model:visible="displayModal_1" :modal="true">-->
                        <!--
                        <img class="w-full hidden md:block" :src="Modal_Elianna_[$i18n.locale]" alt="Elianna Modal">
                        <img  class="w-full md:hidden" :src="Modal_Mobile_Elianna_[$i18n.locale]" alt="Elianna Modal">
                        <div class="p-5 md:p-12 xl:text-2xl">
                            <p class="text-sgbred font-semibold text-xl md:text-2xl">
                                {{ $t("home.modal-1-titel") }}
                            </p>
                            <p class="py-5 md:py-10">
                                {{ $t("home.modal-1-text-1") }}
                            </p>
                            <p>
                                {{ $t("home.modal-1-text-2") }}
                            </p>
                        </div>
                        -->
                        <!--
                        <div class="w-full">
                            <video id="elianna" :src="Video_Elianna_[$i18n.locale]" :autoplay="true" :controls="true" class="w-full" alt="Elianna Modal Video"></video>
                        </div>
                        
                        
                    </Dialog>
                </div>
            </swiper-slide>
            <swiper-slide id="slider_2">
                <img class="hidden md:block" :src="Slider_Cooper_[$i18n.locale]" alt="Cooper Slider">
                <img class="md:hidden" :src="Slider_Mobile_Cooper_[$i18n.locale]" alt="Cooper Slider">
                <div class="absolute bottom-10 items-center left-0 right-0">
                    <Button @click="openModal_2" class="bg-sgbred hover:bg-sgbdarkred py-2 px-2 md:w-300 w-60 border border-white hover:border-transparent rounded-full"><span class="text-white md:text-xl text-base font-bold">{{ $t("header.sl-btn")}}</span></Button>
                    <Dialog id="Cooper" class="w-max md:w-1/2 md:p-0 p-3" v-model:visible="displayModal_2" :modal="true">-->
                        <!--
                        <img class="w-full hidden md:block" :src="Modal_Cooper_[$i18n.locale]" alt="Cooper Modal">
                        <img  class="w-full md:hidden" :src="Modal_Mobile_Cooper_[$i18n.locale]" alt="Cooper Modal">
                        <div class="p-5 md:p-12 xl:text-2xl">
                            <p class="text-sgbred font-semibold text-xl md:text-2xl">
                                {{ $t("home.modal-2-titel") }}
                            </p>
                            <p class="py-5 md:py-10">
                                {{ $t("home.modal-2-text-1") }} 
                            </p>
                            <p>
                                {{ $t("home.modal-2-text-2") }}
                            </p>
                        </div>
                        -->
                        <!--
                        <div class="w-full">
                            <video id="cooper" :src="Video_Cooper_[$i18n.locale]" :autoplay="true" :controls="true" class="w-full" alt="Cooper Modal Video"></video>
                        </div>
                    </Dialog>
                </div>
            </swiper-slide>
            <swiper-slide id="slider_3">
                <img class="hidden md:block" :src="Slider_Corinne_[$i18n.locale]" alt="Corinne Slider">
                <img class="md:hidden" :src="Slider_Mobile_Corinne_[$i18n.locale]" alt="Corinne Slider">
                <div class="absolute bottom-10 items-center left-0 right-0">
                    <Button @click="openModal_3" class="bg-sgbred hover:bg-sgbdarkred py-2 px-2 md:w-300 w-60 border border-white hover:border-transparent rounded-full"><span class="text-white md:text-xl text-base font-bold">{{ $t("header.sl-btn")}}</span></Button>
                    <Dialog id="Corinne" class="w-max md:w-1/2 md:p-0 p-3" v-model:visible="displayModal_3" :modal="true">-->
                        <!--
                        <img class="w-full hidden md:block" :src="Modal_Corinne_[$i18n.locale]" alt="Corinne Modal">
                        <img  class="w-full md:hidden" :src="Modal_Mobile_Corinne_[$i18n.locale]" alt="Corinne Modal">
                        <div class="p-5 md:p-12 xl:text-2xl">
                            <p class="text-sgbred font-semibold text-xl md:text-2xl">
                                {{ $t("home.modal-3-titel") }}
                            </p>
                            <p class="py-5 md:py-10">
                                {{ $t("home.modal-3-text-1") }}
                            </p>
                            <p>
                                {{ $t("home.modal-3-text-2") }}
                            </p>
                        </div>
                        -->
                        <!--
                        <div class="w-full">
                            <video id="corinne" :src="Video_Corinne_[$i18n.locale]" :autoplay="true" :controls="true" class="w-full" alt="Corinne Modal Video"></video>
                        </div>
                    </Dialog>
                </div>
            </swiper-slide>    
        </swiper>
        -->

        <div class="parent-element z-60 right-0">
            <div class="sticky-child top-2/4 md:top-2/4">    
            <button v-on:click="btnClick_de" v-if="$i18n.locale === 'de'" class="bg-sgbred py-2 leading-7 px-5 md:px-8 text-white text-xl md:text-2xl border border-white">{{ $t("header.btn2-1") }}<br>{{ $t("header.btn2-2") }}</button>
            <button v-on:click="btnClick_fr" v-if="$i18n.locale === 'fr'" class="bg-sgbred py-2 leading-7 px-5 md:px-8 text-white text-xl md:text-2xl border border-white">{{ $t("header.btn2-1") }}<br>{{ $t("header.btn2-2") }}</button>
            <button v-on:click="btnClick_it" v-if="$i18n.locale === 'it'" class="bg-sgbred py-2 leading-7 px-5 md:px-8 text-white text-xl md:text-2xl border border-white">{{ $t("header.btn2-1") }}<br>{{ $t("header.btn2-2") }}</button>
            </div>
        </div>

        <div class="section xl:px-40 xl:py-20 py-10 px-5 bg-sgbred text-left">
            <h1 class="xl:text-5xl text-4xl font-bold text-white leading-tight">
                {{ $t("home.title-1") }}
            </h1>
            <p class="xl:text-2xl text-xl text-white pt-5">
                {{ $t("home.text-1") }}
            </p>
            <p class="xl:text-2xl text-xl text-white pt-5">
                {{ $t("home.text-1-1") }}
            </p> 
        </div>
        
        <div class="section xl:px-40 xl:py-20 py-10 px-5 text-left">
            <h1 class="xl:text-5xl text-4xl font-bold text-sgbred leading-tight">
                {{ $t("home.title-1a") }}
            </h1>
            <p class="xl:text-2xl text-xl pt-5">
                {{ $t("home.text-1a") }}
            </p>
        </div>
        <div class="flex flex-wrap mx-auto justify-center xl:px-40 md:flex-nowrap ">
            <vue-flip id="kachel_1" @click="clickKachel1" active-click="" width="100%" height="465px" class="md:mr-2 mb-4 md:w-1/2 w-full  cursor-pointer">
                <template v-slot:front class="front">
                    <div class="h-full bg-cover hidden md:block" :style="{ backgroundImage: 'url(' + front_1 + ')' }">
                        <div class="absolute top-0 right-1 ">
                            <span class="mdi mdi-plus text-6xl"></span>
                        </div>
                        <div class="xl:text-5xl text-4xl font-bold p-10 xl:p-12 leading-normal h-full flex items-center">
                            {{ $t("home.card-front-1") }}
                        </div>
                    </div>
                    <div class="h-full bg-cover md:hidden" :style="{ backgroundImage: 'url(' + front_mobile_1 + ')' }">
                        <div class="absolute top-0 right-1 ">
                            <span class="mdi mdi-plus text-6xl"></span>
                        </div>
                        <div class="xl:text-5xl text-4xl font-bold p-10 xl:p-12 leading-normal h-full flex items-center">
                            {{ $t("home.card-front-1") }}
                        </div>
                    </div>
                </template>
                <template v-slot:back class="back">
                    <div class="bg-sgbred h-full">
                        <div class="absolute top-0 right-1 ">
                            <span class="mdi mdi-close text-6xl"></span>
                        </div>
                        <div class="xl:text-2xl text-lg md:text-xl py-10 px-7 xl:px-10 text-left">
                            <h5 class="font-bold mb-3 xl:mb-5 pr-7">
                                {{ $t("home.card-front-1") }}
                            </h5>
                            <p>
                                {{ $t("home.card-back-1") }}
                            </p>
                        </div>
                    </div>
                </template>
            </vue-flip>
            <vue-flip id="kachel_2" @click="clickKachel2" active-click="" width="100%" height="465px" class="md:ml-2 mb-4 md:w-1/2 w-full cursor-pointer">
                <template v-slot:front class="front">
                    <div class="h-full bg-cover hidden md:block" :style="{ backgroundImage: 'url(' + front_2 + ')' }">
                        <div class="absolute top-0 right-1 ">
                            <span class="mdi mdi-plus text-6xl"></span>
                        </div>
                        <div class="xl:text-5xl text-4xl font-bold p-10 xl:p-12 leading-normal h-full flex items-center">
                            {{ $t("home.card-front-2") }}
                        </div>
                    </div>
                    <div class="h-full bg-cover md:hidden" :style="{ backgroundImage: 'url(' + front_mobile_2 + ')' }">
                        <div class="absolute top-0 right-1 ">
                            <span class="mdi mdi-plus text-6xl"></span>
                        </div>
                        <div class="xl:text-5xl text-4xl font-bold p-10 xl:p-12 leading-normal h-full flex items-center">
                            {{ $t("home.card-front-2") }}
                        </div>
                    </div>
                </template>
                <template v-slot:back class="back">
                    <div class="bg-sgbblue h-full">
                        <div class="absolute top-0 right-1 ">
                            <span class="mdi mdi-close text-6xl"></span>
                        </div>
                        <div class="xl:text-2xl text-lg md:text-xl py-10 px-7 xl:px-10 text-left">
                            <h5 class="font-bold mb-3 xl:mb-5 pr-7">
                                {{ $t("home.card-front-2") }}
                            </h5>
                            <p>
                                {{ $t("home.card-back-2") }}
                            </p>
                        </div>
                    </div>
                </template>
            </vue-flip>
        </div>
        <div class="flex flex-wrap mx-auto justify-center xl:px-40 md:flex-nowrap">
            <vue-flip id="kachel_3" @click="clickKachel3" active-click=""  width="100%" height="465px" class="md:mr-2 mb-4 md:w-1/2 w-full cursor-pointer lg:order-1 order-2">
                <template v-slot:front class="front">
                    <div class="h-full bg-cover hidden md:block" :style="{ backgroundImage: 'url(' + front_3 + ')' }">
                        <div class="absolute top-0 right-1">
                            <span class="mdi mdi-plus text-6xl"></span>
                        </div>
                        <div class="xl:text-5xl text-4xl font-bold p-10 xl:p-12 leading-normal h-full flex items-center">
                            {{ $t("home.card-front-3") }}
                        </div>
                    </div>
                    <div class="h-full bg-cover md:hidden" :style="{ backgroundImage: 'url(' + front_mobile_3 + ')' }">
                        <div class="absolute top-0 right-1">
                            <span class="mdi mdi-plus text-6xl"></span>
                        </div>
                        <div class="xl:text-5xl text-4xl font-bold p-10 xl:p-12 leading-normal h-full flex items-center">
                            {{ $t("home.card-front-3") }}
                        </div>
                    </div>
                </template>
                <template v-slot:back class="back">
                    <div class="bg-sgbblue h-full">
                        <div class="absolute top-0 right-1">
                            <span class="mdi mdi-close text-6xl"></span>
                        </div>
                        <div class="xl:text-2xl text-lg md:text-xl py-10 px-7 xl:px-10 text-left">
                            <h5 class="font-bold mb-3 xl:mb-5 pr-7">
                                {{ $t("home.card-front-3") }}
                            </h5>
                            <p>
                                {{ $t("home.card-back-3") }}
                            </p>
                        </div>
                    </div>
                </template>
            </vue-flip>
            <vue-flip id="kachel_4" @click="clickKachel4" active-click="" width="100%" height="465px" class="md:ml-2 mb-4 md:w-1/2 w-full cursor-pointer md:order-2 order-1">
                <template v-slot:front class="front">
                    <div class="h-full bg-cover hidden md:block" :style="{ backgroundImage: 'url(' + front_4 + ')' }">
                        <div class="absolute top-0 right-1">
                            <span class="mdi mdi-plus text-6xl"></span>
                        </div>
                        <div class="xl:text-5xl text-4xl font-bold p-10 xl:p-12 leading-normal h-full flex items-center">
                            {{ $t("home.card-front-4") }}
                        </div>
                    </div>
                    <div class="h-full bg-cover md:hidden" :style="{ backgroundImage: 'url(' + front_mobile_4 + ')' }">
                        <div class="absolute top-0 right-1">
                            <span class="mdi mdi-plus text-6xl"></span>
                        </div>
                        <div class="xl:text-5xl text-4xl font-bold p-10 xl:p-12 leading-normal h-full flex items-center">
                            {{ $t("home.card-front-4") }}
                        </div>
                    </div>
                </template>
                <template v-slot:back class="back">
                    <div class="bg-sgbred h-full">
                        <div class="absolute top-0 right-1">
                            <span class="mdi mdi-close text-6xl"></span>
                        </div>
                        <div class="xl:text-2xl text-lg md:text-xl py-10 px-7 xl:px-10 text-left">
                            <h5 class="font-bold mb-3 xl:mb-5 pr-7">
                                {{ $t("home.card-front-4") }}
                            </h5>
                            <p>
                                {{ $t("home.card-back-4") }}
                            </p>
                        </div>
                    </div>
                </template>
            </vue-flip>
        </div>
        <div class="flex flex-wrap mx-auto justify-center xl:px-40 md:flex-nowrap">
            <vue-flip id="kachel_5" @click="clickKachel5" active-click="" width="100%" height="465px" class="md:mr-2 mb-4 md:w-1/2 w-full cursor-pointer">
                <template v-slot:front class="front">
                    <div class="h-full bg-cover hidden md:block" :style="{ backgroundImage: 'url(' + front_5 + ')' }">
                        <div class="absolute top-0 right-1 ">
                            <span class="mdi mdi-plus text-6xl"></span>
                        </div>
                        <div class="xl:text-5xl text-4xl font-bold p-10 xl:p-12 leading-normal h-full flex items-center">
                            {{ $t("home.card-front-5") }}
                        </div>
                    </div>
                    <div class="h-full bg-cover md:hidden" :style="{ backgroundImage: 'url(' + front_mobile_5 + ')' }">
                        <div class="absolute top-0 right-1 ">
                            <span class="mdi mdi-plus text-6xl"></span>
                        </div>
                        <div class="xl:text-5xl text-4xl font-bold p-10 xl:p-12 leading-normal h-full flex items-center">
                            {{ $t("home.card-front-5") }}
                        </div>
                    </div>
                </template>
                <template v-slot:back class="back">
                    <div class="bg-sgbred h-full">
                        <div class="absolute top-0 right-1 ">
                            <span class="mdi mdi-close text-6xl"></span>
                        </div>
                        <div class="xl:text-2xl text-lg md:text-xl py-10 px-7 xl:px-10 text-left">
                            <h5 class="font-bold mb-3 xl:mb-5 pr-7">
                                {{ $t("home.card-front-5") }}
                            </h5>
                            <p>
                                {{ $t("home.card-back-5") }}
                            </p>
                        </div>
                    </div>
                </template>
            </vue-flip>
            <vue-flip id="kachel_6" @click="clickKachel6" active-click="" width="100%" height="465px" class="md:ml-2 mb-4 md:w-1/2 w-full cursor-pointer">
                <template v-slot:front class="front">
                    <div class="h-full bg-cover hidden md:block" :style="{ backgroundImage: 'url(' + front_6 + ')' }">
                        <div class="absolute top-0 right-1 ">
                            <span class="mdi mdi-plus text-6xl"></span>
                        </div>
                        <div class="xl:text-5xl text-4xl font-bold p-9 xl:p-12 leading-normal h-full flex items-center">
                            {{ $t("home.card-front-6") }}
                        </div>
                    </div>
                    <div class="h-full bg-cover md:hidden" :style="{ backgroundImage: 'url(' + front_mobile_6 + ')' }">
                        <div class="absolute top-0 right-1 ">
                            <span class="mdi mdi-plus text-6xl"></span>
                        </div>
                        <div class="xl:text-5xl text-4xl font-bold p-9 xl:p-12 leading-normal h-full flex items-center">
                            {{ $t("home.card-front-6") }}
                        </div>
                    </div>
                </template>
                <template v-slot:back class="back">
                    <div class="bg-sgbblue h-full">
                        <div class="absolute top-0 right-1 ">
                            <span class="mdi mdi-close text-6xl"></span>
                        </div>
                        <div class="xl:text-2xl text-lg md:text-xl py-10 px-7 xl:px-10 text-left">
                            <h5 class="font-bold mb-3 xl:mb-5 pr-7">
                                {{ $t("home.card-front-6") }}
                            </h5>
                            <p>
                                {{ $t("home.card-back-6") }}
                            </p>
                        </div>
                    </div>
                </template>
            </vue-flip>
        </div>
        <div class="section xl:mt-24 xl:px-40 xl:py-20 py-10 px-5 text-left text-white bg-sgbblue">
            <h1 class="xl:text-5xl text-4xl font-bold leading-tight">
                {{ $t("home.title-2") }}
            </h1>
            <p class="xl:text-2xl text-xl pt-5">
                {{ $t("home.text-2") }}
            </p>
        </div>
        <div class="section xl:px-40 xl:py-20 py-10 px-5 text-left text-white bg-sgblightblue">
            <p class="xl:text-2xl text-xl xl:leading-relaxed">
                <span class="font-bold">{{ $t("home.text-3") }}</span>
                <ul class="dashed list-outside mt-5">
                    <li>{{ $t("home.text-3-1") }}</li>
                    <li>{{ $t("home.text-3-2") }}</li>
                    <li>{{ $t("home.text-3-3") }}</li>
                    <li>{{ $t("home.text-3-4") }}</li>
                    <li>{{ $t("home.text-3-5") }}</li>
                    <li>{{ $t("home.text-3-6") }}</li>
                </ul>
            </p>
            <p class="xl:text-2xl text-xl xl:leading-loose font-bold underline">
                <a v-if="$i18n.locale === 'de'" href="https://www.sgb-fss.ch/" target="blank">{{ $t("home.text-3-7") }}</a>
                <a v-if="$i18n.locale === 'fr'" href="https://www.sgb-fss.ch/fr/" target="blank">{{ $t("home.text-3-7") }}</a>
                <a v-if="$i18n.locale === 'it'" href="https://www.sgb-fss.ch/it/" target="blank">{{ $t("home.text-3-7") }}</a>
            </p>
        </div>
    </div>

</template>

<script>

    // Import Swiper Vue.js components
    /*import { Swiper, SwiperSlide } from 'swiper/vue';*/
    

    // Import Swiper styles
    import 'swiper/swiper-bundle.css';
    //import 'swiper/swiper.scss';
    import "swiper/components/navigation/navigation.min.css"
    import "swiper/components/pagination/pagination.min.css"

    // import Swiper core and required modules
    import SwiperCore, {
    Navigation,Pagination,Mousewheel,Autoplay,Keyboard
    } from 'swiper/core';

    // install Swiper modules
    SwiperCore.use([Navigation,Pagination,Mousewheel,Autoplay,Keyboard]);

    //import { VueperSlides, VueperSlide } from 'vueperslides'
    //import 'vueperslides/dist/vueperslides.css'

    import VueFlip from 'vue-flip';

    // Source Wechsel (Bild) je nach Sprache
    import Slider_Elianna_de from '@/assets/img/slider/Slider_Elianna_de.jpg'
    import Slider_Elianna_fr from '@/assets/img/slider/Slider_Elianna_fr.jpg'
    import Slider_Elianna_it from '@/assets/img/slider/Slider_Elianna_it.jpg'
    import Slider_Mobile_Elianna_de from '@/assets/img/slider/Slider_Mobile_Elianna_de.jpg'
    import Slider_Mobile_Elianna_fr from '@/assets/img/slider/Slider_Mobile_Elianna_fr.jpg'
    import Slider_Mobile_Elianna_it from '@/assets/img/slider/Slider_Mobile_Elianna_it.jpg'

    import Modal_Elianna_de from '@/assets/img/modal/Modal_Elianna_de.png'
    import Modal_Elianna_fr from '@/assets/img/modal/Modal_Elianna_fr.png'
    import Modal_Elianna_it from '@/assets/img/modal/Modal_Elianna_it.png'
    import Modal_Mobile_Elianna_de from '@/assets/img/modal/Modal_Mobile_Elianna_de.png'
    import Modal_Mobile_Elianna_fr from '@/assets/img/modal/Modal_Mobile_Elianna_fr.png'
    import Modal_Mobile_Elianna_it from '@/assets/img/modal/Modal_Mobile_Elianna_it.png'

    import Slider_Cooper_de from '@/assets/img/slider/Slider_Cooper_de.jpg'
    import Slider_Cooper_fr from '@/assets/img/slider/Slider_Cooper_fr.jpg'
    import Slider_Cooper_it from '@/assets/img/slider/Slider_Cooper_it.jpg'
    import Slider_Mobile_Cooper_de from '@/assets/img/slider/Slider_Mobile_Cooper_de.jpg'
    import Slider_Mobile_Cooper_fr from '@/assets/img/slider/Slider_Mobile_Cooper_fr.jpg'
    import Slider_Mobile_Cooper_it from '@/assets/img/slider/Slider_Mobile_Cooper_it.jpg'

    import Modal_Cooper_de from '@/assets/img/modal/Modal_Cooper_de.png'
    import Modal_Cooper_fr from '@/assets/img/modal/Modal_Cooper_fr.png'
    import Modal_Cooper_it from '@/assets/img/modal/Modal_Cooper_it.png'
    import Modal_Mobile_Cooper_de from '@/assets/img/modal/Modal_Mobile_Cooper_de.png'
    import Modal_Mobile_Cooper_fr from '@/assets/img/modal/Modal_Mobile_Cooper_fr.png'
    import Modal_Mobile_Cooper_it from '@/assets/img/modal/Modal_Mobile_Cooper_it.png'

    import Slider_Corinne_de from '@/assets/img/slider/Slider_Corinne_de.jpg'
    import Slider_Corinne_fr from '@/assets/img/slider/Slider_Corinne_fr.jpg'
    import Slider_Corinne_it from '@/assets/img/slider/Slider_Corinne_it.jpg'
    import Slider_Mobile_Corinne_de from '@/assets/img/slider/Slider_Mobile_Corinne_de.jpg'
    import Slider_Mobile_Corinne_fr from '@/assets/img/slider/Slider_Mobile_Corinne_fr.jpg'
    import Slider_Mobile_Corinne_it from '@/assets/img/slider/Slider_Mobile_Corinne_it.jpg'

    import Modal_Corinne_de from '@/assets/img/modal/Modal_Corinne_de.png'
    import Modal_Corinne_fr from '@/assets/img/modal/Modal_Corinne_fr.png'
    import Modal_Corinne_it from '@/assets/img/modal/Modal_Corinne_it.png'
    import Modal_Mobile_Corinne_de from '@/assets/img/modal/Modal_Mobile_Corinne_de.png'
    import Modal_Mobile_Corinne_fr from '@/assets/img/modal/Modal_Mobile_Corinne_fr.png'
    import Modal_Mobile_Corinne_it from '@/assets/img/modal/Modal_Mobile_Corinne_it.png'

    import Video_Elianna_de from '@/assets/video/sgb-elianna-geschichte-de.mp4'
    import Video_Elianna_fr from '@/assets/video/sgb-elianna-geschichte-fr.mp4'
    import Video_Elianna_it from '@/assets/video/sgb-elianna-geschichte-it.mp4'
    import Video_Cooper_de from '@/assets/video/sgb-cooper-geschichte-de.mp4'
    import Video_Cooper_fr from '@/assets/video/sgb-cooper-geschichte-fr.mp4'
    import Video_Cooper_it from '@/assets/video/sgb-cooper-geschichte-it.mp4'
    import Video_Corinne_de from '@/assets/video/sgb-corinne-geschichte-de.mp4'
    import Video_Corinne_fr from '@/assets/video/sgb-corinne-geschichte-fr.mp4'
    import Video_Corinne_it from '@/assets/video/sgb-corinne-geschichte-it.mp4'

    import Video_Header_de from '@/assets/video/sgb-header-de.mp4'
    import Video_Header_fr from '@/assets/video/sgb-header-fr.mp4'
    import Video_Header_it from '@/assets/video/sgb-header-it.mp4'

    import Video_Header_Mobile_de from '@/assets/video/sgb-header-mobile-de.mp4'
    import Video_Header_Mobile_fr from '@/assets/video/sgb-header-mobile-fr.mp4'
    import Video_Header_Mobile_it from '@/assets/video/sgb-header-mobile-it.mp4'

    import Poster_Header_de from '@/assets/img/sgb-header-de.jpg'
    import Poster_Header_fr from '@/assets/img/sgb-header-fr.jpg'
    import Poster_Header_it from '@/assets/img/sgb-header-it.jpg'

    import Poster_Header_Mobile_de from '@/assets/img/sgb-header-mobile-de.jpg'
    import Poster_Header_Mobile_fr from '@/assets/img/sgb-header-mobile-fr.jpg'
    import Poster_Header_Mobile_it from '@/assets/img/sgb-header-mobile-it.jpg'

    // Aufruf Kachel per Anker-Link
    const TIMEOUT = 1;

    export default {
        name: 'Home',
        
        data() {
            return {
                displayModal_1: false,
                displayModal_2: false,
                displayModal_3: false,
                position: 'center',

                swiperOption: {
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                },

                // Card Images
                front_1: require('@/assets/img/Kachel_1.png'),
                front_2: require('@/assets/img/Kachel_2.png'),
                front_3: require('@/assets/img/Kachel_3.png'),
                front_4: require('@/assets/img/Kachel_4.png'),
                front_5: require('@/assets/img/Kachel_5.png'),
                front_6: require('@/assets/img/Kachel_6.png'),
                front_mobile_1: require('@/assets/img/Kachel_Mobile_1.png'),
                front_mobile_2: require('@/assets/img/Kachel_Mobile_2.png'),
                front_mobile_3: require('@/assets/img/Kachel_Mobile_3.png'),
                front_mobile_4: require('@/assets/img/Kachel_Mobile_4.png'),
                front_mobile_5: require('@/assets/img/Kachel_Mobile_5.png'),
                front_mobile_6: require('@/assets/img/Kachel_Mobile_6.png'),
                

                // Source Wechsel (Bild) je nach Sprache
                Slider_Elianna_: {
                    de: Slider_Elianna_de,
                    fr: Slider_Elianna_fr,
                    it: Slider_Elianna_it
                },
                Slider_Mobile_Elianna_: {
                    de: Slider_Mobile_Elianna_de,
                    fr: Slider_Mobile_Elianna_fr,
                    it: Slider_Mobile_Elianna_it
                },
                Modal_Elianna_: {
                    de: Modal_Elianna_de,
                    fr: Modal_Elianna_fr,
                    it: Modal_Elianna_it
                },
                Modal_Mobile_Elianna_: {
                    de: Modal_Mobile_Elianna_de,
                    fr: Modal_Mobile_Elianna_fr,
                    it: Modal_Mobile_Elianna_it
                },
                
                Slider_Cooper_: {
                    de: Slider_Cooper_de,
                    fr: Slider_Cooper_fr,
                    it: Slider_Cooper_it
                },
                Slider_Mobile_Cooper_: {
                    de: Slider_Mobile_Cooper_de,
                    fr: Slider_Mobile_Cooper_fr,
                    it: Slider_Mobile_Cooper_it
                },
                Modal_Cooper_: {
                    de: Modal_Cooper_de,
                    fr: Modal_Cooper_fr,
                    it: Modal_Cooper_it
                },
                Modal_Mobile_Cooper_: {
                    de: Modal_Mobile_Cooper_de,
                    fr: Modal_Mobile_Cooper_fr,
                    it: Modal_Mobile_Cooper_it
                },

                Slider_Corinne_: {
                    de: Slider_Corinne_de,
                    fr: Slider_Corinne_fr,
                    it: Slider_Corinne_it
                },
                Slider_Mobile_Corinne_: {
                    de: Slider_Mobile_Corinne_de,
                    fr: Slider_Mobile_Corinne_fr,
                    it: Slider_Mobile_Corinne_it
                },
                Modal_Corinne_: {
                    de: Modal_Corinne_de,
                    fr: Modal_Corinne_fr,
                    it: Modal_Corinne_it
                },
                Modal_Mobile_Corinne_: {
                    de: Modal_Mobile_Corinne_de,
                    fr: Modal_Mobile_Corinne_fr,
                    it: Modal_Mobile_Corinne_it
                },
                Poster_Header_: {
                    de: Poster_Header_de,
                    fr: Poster_Header_fr,
                    it: Poster_Header_it
                },
                Poster_Header_Mobile_: {
                    de: Poster_Header_Mobile_de,
                    fr: Poster_Header_Mobile_fr,
                    it: Poster_Header_Mobile_it
                },

                // ------ Video im Modal ------
                Video_Elianna_: {
                    de: Video_Elianna_de,
                    fr: Video_Elianna_fr,
                    it: Video_Elianna_it
                },
                Video_Cooper_: {
                    de: Video_Cooper_de,
                    fr: Video_Cooper_fr,
                    it: Video_Cooper_it
                },
                Video_Corinne_: {
                    de: Video_Corinne_de,
                    fr: Video_Corinne_fr,
                    it: Video_Corinne_it
                },
                Video_Header_: {
                    de: Video_Header_de,
                    fr: Video_Header_fr,
                    it: Video_Header_it
                },
                Video_Header_Mobile_: {
                    de: Video_Header_Mobile_de,
                    fr: Video_Header_Mobile_fr,
                    it: Video_Header_Mobile_it
                },
            }
            
        },
           
        components: { /*VueperSlides, VueperSlide,*/ 'vue-flip': VueFlip, /*Swiper, SwiperSlide*/ },


        mounted() {

            // Aufruf Modals
            this.$nextTick(() => {
                
                if (window.location.search.indexOf('openDialog_1') > -1) {
                    this.openModal_1();
                        setTimeout(() => {
                            document.getElementById("elianna").play();
                        }, 3000);
                }
                if (window.location.search.indexOf('openDialog_2') > -1) {
                    this.openModal_2();
                        setTimeout(() => {
                            document.getElementById("cooper").play();
                        }, 3000);
                }
                if (window.location.search.indexOf('openDialog_3') > -1) {
                    this.openModal_3();
                        setTimeout(() => {
                            document.getElementById("corinne").play();
                        }, 3000);
                }
            });

            // Aufruf Kachel per Anker-Link
            if (this.$route.hash) {
                setTimeout(() => this.scrollTo(this.$route.hash), TIMEOUT)
            }
            
        },

        methods: {
            // Swiper Slider
            onSwiper(swiper) {
                console.log(swiper);
            },
            onSlideChange() {
                console.log('slide change');
            },

            // Öffnen des Modals
            openModal_1() {
                this.displayModal_1 = true;
                 window.dataLayer.push({ 'event': 'clickSlider', 'slider': 'slider_1' });
            },
            closeModal_1() {
                this.displayModal_1 = false;
            },
            openModal_2() {
                this.displayModal_2 = true;
                window.dataLayer.push({ 'event': 'clickSlider', 'slider': 'slider_2' });
            },
            closeModal_2() {
                this.displayModal_2 = false;
            },
            openModal_3() {
                this.displayModal_3 = true;
                window.dataLayer.push({ 'event': 'clickSlider', 'slider': 'slider_3' });
            },
            closeModal_3() {
                this.displayModal_3 = false;
            },

            clickKachel1 () {
               window.dataLayer.push({ 'event': 'clickKachel', 'kachel': 'kachel_1' });
            },
            clickKachel2 () {
               window.dataLayer.push({ 'event': 'clickKachel', 'kachel': 'kachel_2' });
            },
            clickKachel3 () {
               window.dataLayer.push({ 'event': 'clickKachel', 'kachel': 'kachel_3' });
            },
            clickKachel4 () {
               window.dataLayer.push({ 'event': 'clickKachel', 'kachel': 'kachel_4' });
            },
            clickKachel5 () {
               window.dataLayer.push({ 'event': 'clickKachel', 'kachel': 'kachel_5' });
            },
            clickKachel6 () {
               window.dataLayer.push({ 'event': 'clickKachel', 'kachel': 'kachel_6' });
            },

            linkClick_de: function() {
            window.open("https://www.sgb-fss.ch")
            },
            linkClick_fr: function() {
                window.open("https://www.sgb-fss.ch/fr/")
            },
            linkClick_it: function() {
                window.open("https://www.sgb-fss.ch/it/")
            },

            // Damit Anker-Links nach Pageload funktionieren
            scrollTo: function (hashtag) {
            setTimeout(() => { location.href = hashtag }, TIMEOUT)
            },

            // Sticky Button
            btnClick_de: function() {
            window.open("https://www.sgb-fss.ch/spenden/jetzt-spenden/")
            },
            btnClick_fr: function() {
                window.open("https://www.sgb-fss.ch/fr/soutien/dons/")
            },
            btnClick_it: function() {
                window.open("https://www.sgb-fss.ch/it/donazioni/dona-ora/")
            },

        }
    }

</script>

<style>

    /*-------- Slider --------*/
    .swiper-button-prev, .swiper-container-rtl .swiper-button-next,
    .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
        color: white;
    }
    .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        display: inline-block;
        border-radius: 50%;
        padding: 0;
        background: transparent;
        opacity: 1;
        border: 1px solid white;
    }
    .swiper-pagination-bullet-active {
        background: none;
        border: 0;
        box-sizing: border-box;
        
    }
    .swiper-pagination-bullet-active::before {
        font-family: "Material Design Icons";
        content: "\F043E";
        color: white;
        margin: 0 -1px;
    }

    /*-------- Cards --------*/
    .front {
        /*display: flex;*/
        align-items: center;
        justify-content: center;
        color: white;
        width: 100%;
        height: 100%;
    }
    .back {
        display: flex;
        align-items: center;
        justify-content: center;
        
        color: white;
        width: 100%;
        height: 100%;
    }

    ul {
        margin: 0;
    }
    ul.dashed {
        list-style-type:none;
        margin-left: 30px;
    }
    ul.dashed > li {
        text-indent: 0;
        margin-bottom: 15px;
    }
    ul.dashed > li:before {
        display: inline-block;
        content: "-";
        width: 1em;
        margin-left: -1em;
    }

    /*-----Dialog-------*/
    .p-dialog .p-dialog-header {
        position: absolute;
        right: 0;
        background: transparent !important;
        color: white;
        padding: .5rem !important;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
        background: gray !important;
    }
    .p-dialog-content {
        padding: 0 !important;
    }
    .pi {
        font-size: 2rem !important;
    }
    .pi-times:before {
        color: white;
    }
    
    .p-dialog .p-dialog-header .p-dialog-header-icon {
        width: 4rem !important;
        height: 4rem !important;
        z-index: 10;
    }
    @media screen and (max-width: 640px) {
        .p-dialog .p-dialog-header {
            padding: 0 .5rem !important;
        }
        .pi {
        font-size: 1.5rem !important;
        }
        .p-dialog .p-dialog-header .p-dialog-header-icon {
            width: 3rem !important;
            height: 3rem !important;
        }
    }

    /*-------- Button --------*/
    .p-button {
        color: #ffffff;
        background: #E65834 !important;
        border: 0 !important;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        border-radius: 20px !important;
        display: inline !important;
    }
    .p-button:hover {
        background: #C1492B !important;
    }
    .p-button-label {
        font-size: 1.25rem;
        line-height: 1.5rem;
        font-weight: bold;
        font-family: Helvetica;
    }
    @media screen and (max-width: 640px) {
        .p-button-label {
            font-size: 1rem;
            line-height: 1.25rem;
        }
    }

    /* ---- Sticky-Button ---- */
    .parent-element {
        position: absolute;
        overflow: visible;
        height: 100%;
        z-index: 9999;
    }
    
    .sticky-child {
        position: fixed;
        right: 0;
    }

</style>

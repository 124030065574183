<template>
  <div id="app" class="min-h-screen">
    <!--<Header />-->
    <Navigation />
    <router-view />
    <Footer />
  </div>
</template>

<script>
  //import Header from './components/Header.vue';
  import Footer from './components/Footer.vue';
  import Navigation from './components/Navigation.vue';
  

export default {
  name: 'App',

  components: {
    //Header,
    Footer,
    Navigation,
  },

}
</script>

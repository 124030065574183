<template>
    <footer v-if="$i18n.locale === 'de'" class="text-white w-full body-font">
        <div class="container xl:px-40 px-5 xl:py-24 py-12 mx-auto bg-sgbgrey">
            <div class="xl:flex xl:flex-wrap flex-nowrap text-left -mb-10 -mx-4">
                <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                    <img src="../assets/img/sgb-fss-logo-de.png">
                    <p class="mt-3">
                        Schweizerischer Gehörlosenbund<br>
                        Räffelstrasse 24<br>
                        8045 Zürich<br>
                        Telefon 044 315 50 40<br>
                        <a href="mailto:info-d@sgb-fss.ch" class="cursor-pointer hover:underline"><span class="noSpam-text"><img class="inline" src="../assets/img/mail_icon.png" alt="E-Mail"> E-Mail</span></a>
                    </p>
                </div>
                <div class="lg:w-2/4 md:w-1/2 w-full px-4 xl:mt-0 mt-5">
                    <h3 class="xl:text-2xl text-xl font-bold mb-3">Unsere Mission</h3>
                    <p>Der Dachverband Schweizerischer Gehörlosenbund SGB-FSS setzt sich mit seinen Mitgliedern und Partnerorganisationen dafür ein, dass Zugangsbarrieren in Bildung, Arbeit, Gesundheit, Politik, Kultur und Gesellschaft abgebaut und die Rechte der Menschen mit einer Hörbehinderung konsequent und nachhaltig umgesetzt werden.</p>
                    <div class="footrcnt mt-5">
                        <h3 class="xl:text-2xl text-xl font-bold mb-3">Folgen Sie uns</h3>
                        <a href="https://www.facebook.com/pages/Schweizerischer-Geh%C3%B6rlosenbund-SGB-FSS/100925563313615" target="_blank" class="facebook">Facebook</a>
                        <a href="https://twitter.com/sgbfss" target="_blank" class="twitter">Twitter</a>
                        <a href="https://www.youtube.com/channel/UCCO2gpEd2Sw1i8uO1wpJ_lQ" target="_blank" class="youtube">Youtube</a>
                    </div>
                </div>
                <div class="lg:w-1/4 md:w-1/2 w-full px-4 xl:mt-0 mt-5">
                    <h2 class="xl:text-2xl text-xl font-bold mb-3">Newsletter</h2>
                    <div class="my-3">
                        <div v-if="$i18n.locale === 'de'" id="mc_embed_signup">
                            <form action="https://sgb-fss.us11.list-manage.com/subscribe/post?u=ccbb18229f86231691c4bfde5&amp;id=063fdf6a08" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                                <div id="mc_embed_signup_scroll">
                            <div class="mc-field-group">
                                <input class="text-black" type="radio" id="Herr" name="ANREDE" value="Herr">
                                <label for="Herr">Herr</label> 
                                <input type="radio" id="Frau" name="ANREDE" value="Frau">
                                <label for="Frau">Frau</label><br>	
                            </div>
                            <div class="mc-field-group">
                                <!--<label for="mce-EMAIL">E-Mail-Adresse</label>-->
                                <input placeholder="E-Mail-Adresse" type="email" value="" name="EMAIL" class="required email text-black" id="mce-EMAIL">
                            </div>
                            <div class="mc-field-group">
                                <!--<label for="mce-FNAME">Vorname</label>-->
                                <input placeholder="Vorname" type="text" value="" name="FNAME" class="text-black" id="mce-FNAME">
                            </div>
                            <div class="mc-field-group">
                                <!--<label for="mce-LNAME">Nachname</label>-->
                                <input placeholder="Nachname" type="text" value="" name="LNAME" class="required text-black" id="mce-LNAME">
                            </div>
                                <div id="mce-responses" class="clear">
                                    <div class="response" id="mce-error-response" style="display:none"></div>
                                    <div class="response" id="mce-success-response" style="display:none"></div>
                                </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_ccbb18229f86231691c4bfde5_063fdf6a08" tabindex="-1" value=""></div>
                                <div class="clear"><input type="submit" value="Anmelden" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <img src="../assets/img/zewo.png">
                    <p class="mt-3">Spendenkonto: 80-26467-1<br>IBAN: CH93 0900 0000 8002 6467 1<br>BIC: POFICHBEXXX</p>
                   
                </div>
                
            </div>
        </div>
        
        <div class="container xl:px-40 px-5 xl:py-4 py-2 mx-auto bg-sgbdarkgrey text-left">
            <a href="https://www.sgb-fss.ch/ueber_uns/kontakt-2/" target="_blank">Kontakt</a>
            &nbsp;|&nbsp;
            <a href="https://www.sgb-fss.ch/footer/datenschutz/" target="_blank">Datenschutz</a>
            &nbsp;|&nbsp;
            <a href="https://www.sgb-fss.ch/footer/rechtliche-hinweise/" target="_blank">Rechtliche Hinweise</a>
            &nbsp;|&nbsp;
            <a href="https://www.sgb-fss.ch/footer/impressum/" target="_blank">Impressum</a>
            &nbsp;|&nbsp;
            <a href="https://www.sgb-fss.ch/footer/agb/" target="_blank">AGB</a>
        </div> 
    </footer>
    <footer v-if="$i18n.locale === 'fr'" class="text-white w-full body-font">
        <div class="container xl:px-40 px-5 xl:py-24 py-12 mx-auto bg-sgbgrey">
            <div class="xl:flex xl:flex-wrap flex-nowrap text-left -mb-10 -mx-4">
                <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                    <img src="../assets/img/sgb-fss-logo-fr.png">
                    <p class="mt-3">
                        Fédération Suisse des Sourds SGB-FSS<br>
                        Passage St-François 12<br>
                        1003 Lausanne<br>
                        Téléphone: 021-625 65 55<br>
                        <a href="mailto:info-d@sgb-fss.ch" class="cursor-pointer hover:underline"><span class="noSpam-text"><img class="inline" src="../assets/img/mail_icon.png" alt="E-Mail"> Courriel</span></a>
                    </p>
                </div>
                <div class="lg:w-2/4 md:w-1/2 w-full px-4 xl:mt-0 mt-5">
                    <h3 class="xl:text-2xl text-xl font-bold mb-3">Notre mission</h3>
                    <p>En tant qu’association faîtière, la Fédération Suisse des Sourds SGB-FSS s’engage avec ses membres et ses partenaires en faveur de l’élimination des barrières qui entravent l’accès des personnes sourdes et malentendantes à l’éducation, la formation, l’emploi, la santé, la politique, la culture et la société et de l’application systématique et durable des droits des personnes sourdes et malentendantes.</p>
                    <div class="footrcnt mt-5">
                        <h3 class="xl:text-2xl text-xl font-bold mb-3">Suivez-nous</h3>
                        <a href="https://www.facebook.com/pages/F%C3%A9d%C3%A9ration-suisse-des-sourds/458245287536698?fref=ts" target="_blank" class="facebook">Facebook</a>
                        <a href="https://twitter.com/sgbfss" target="_blank" class="twitter">Twitter</a>
                        <a href="https://www.youtube.com/channel/UCCO2gpEd2Sw1i8uO1wpJ_lQ" target="_blank" class="youtube">Youtube</a>
                    </div>
                </div>
                <div class="lg:w-1/4 md:w-1/2 w-full px-4 xl:mt-0 mt-5">
                    <h2 class="xl:text-2xl text-xl font-bold mb-3">Newsletter</h2>
                    <div class="my-3">
                        <div id="mc_embed_signup">
                            <form action="https://sgb-fss.us11.list-manage.com/subscribe/post?u=ccbb18229f86231691c4bfde5&amp;id=184bb719fc" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                                <div id="mc_embed_signup_scroll">
                            <div class="mc-field-group">
                                <input class="text-black" type="radio" id="Monsieur" name="ANREDE" value="Monsieur">
                                <label for="Monsieur">Monsieur</label> 
                                <input type="radio" id="Madame" name="ANREDE" value="Madame">
                                <label for="Madame">Madame</label><br>	
                            </div>
                            <div class="mc-field-group">
                                <input placeholder="Adresse e-mail" type="email" value="" name="EMAIL" class="required email text-black" id="mce-EMAIL">
                            </div>
                            <div class="mc-field-group">
                                <input placeholder="Prénom" type="text" value="" name="FNAME" class="required text-black" id="mce-FNAME">
                            </div>
                            <div class="mc-field-group">
                                <input placeholder="Nom" type="text" value="" name="LNAME" class="required text-black" id="mce-LNAME">
                            </div>
                                <div id="mce-responses" class="clear">
                                    <div class="response" id="mce-error-response" style="display:none"></div>
                                    <div class="response" id="mce-success-response" style="display:none"></div>
                                </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_ccbb18229f86231691c4bfde5_184bb719fc" tabindex="-1" value=""></div>
                                <div class="clear"><input type="submit" value="Je m'abonne" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <img src="../assets/img/zewo.png" alt="Zewo Zertifikat">
                    <p class="mt-3">Compte pour dons:  10-13312-0<br>IBAN: CH07 0900 0000 1001 3312 0<br>BIC: POFICHBEXXX</p>
                </div>
            </div>
        </div>
        <div class="container xl:px-40 px-5 xl:py-4 py-2 mx-auto bg-sgbdarkgrey text-left">
            <a href="https://www.sgb-fss.ch/fr/a-notre-propos/contact/" target="_blank">Contact</a>
            &nbsp;|&nbsp;
            <a href="https://www.sgb-fss.ch/fr/footer/indication-judiciaire/" target="_blank">Indication judiciaire</a>
            &nbsp;|&nbsp;
            <a href="https://www.sgb-fss.ch/fr/footer/protection-des-donnees/" target="_blank">Protection des données</a>
            &nbsp;|&nbsp;
            <a href="https://www.sgb-fss.ch/fr/footer/impressum/" target="_blank">Impressum</a
            >&nbsp;|&nbsp;
            <a href="https://www.sgb-fss.ch/fr/footer/agb/" target="_blank">Conditions générales</a>
        </div> 
    </footer>
    <footer v-if="$i18n.locale === 'it'" class="text-white w-full body-font">
        <div class="container xl:px-40 px-5 xl:py-24 py-12 mx-auto bg-sgbgrey">
            <div class="xl:flex xl:flex-wrap flex-nowrap text-left -mb-10 -mx-4">
                <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                    <img src="../assets/img/sgb-fss-logo-it.png">
                     <p class="mt-3">
                        Federazione Svizzera dei Sordi SGB-FSS<br>
                        Via Besso 5<br>
                        6900 Lugano<br>
                        Telefono/SMS: 091 950 05 48<br>
                        <a href="mailto:info-d@sgb-fss.ch" class="cursor-pointer hover:underline"><span class="noSpam-text"><img class="inline" src="../assets/img/mail_icon.png" alt="E-Mail"> Email</span></a>
                    </p>
                </div>
                <div class="lg:w-2/4 md:w-1/2 w-full px-4 xl:mt-0 mt-5">
                    <h3 class="xl:text-2xl text-xl font-bold mb-3">La nostra missione</h3>
                    <p>Quale organizzazione mantello, la Federazione Svizzera dei Sordi SGB-FSS si impegna,  insieme ai suoi membri e alle organizzazioni partner per abbattere le barriere che ostacolano l’accesso all’educazione e alla formazione, al lavoro, alla salute, politica, cultura e società nonché per l’applicazione sistematica e continuativa dei diritti delle persone sorde e audiolese.</p>
                    <div class="footrcnt mt-5">
                        <h3 class="xl:text-2xl text-xl font-bold mb-3">Suivez-nous</h3>
                        <a href="https://www.facebook.com/pages/Federazione-svizzera-dei-sordi-SGB-FSS/890748174305871?fref=ts" target="_blank" class="facebook">Facebook</a>
                        <a href="https://twitter.com/sgbfss" target="_blank" class="twitter">Twitter</a>
                        <a href="https://www.youtube.com/channel/UCCO2gpEd2Sw1i8uO1wpJ_lQ" target="_blank" class="youtube">Youtube</a>
                    </div>
                </div>
                <div class="lg:w-1/4 md:w-1/2 w-full px-4 xl:mt-0 mt-5">
                    <h2 class="xl:text-2xl text-xl font-bold mb-3">Newsletter</h2>
                    <div class="my-3">
                        <div id="mc_embed_signup">
                            <form action="https://sgb-fss.us11.list-manage.com/subscribe/post?u=ccbb18229f86231691c4bfde5&amp;id=5f1d15364f" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                                <div id="mc_embed_signup_scroll">
                            <div class="mc-field-group">
                                <input class="text-black" type="radio" id="Signor" name="ANREDE" value="Signor">
                                <label for="Signor">Signor</label> 
                                <input type="radio" id="Signora" name="ANREDE" value="Signora">
                                <label for="Signora">Signora</label><br>	
                            </div>
                            <div class="mc-field-group">
                                <input placeholder="Indirizzi e-mail" type="email" value="" name="EMAIL" class="required email text-black" id="mce-EMAIL">
                            </div>
                            <div class="mc-field-group">
                                <input placeholder="Nome" type="text" value="" name="FNAME" class="required text-black" id="mce-FNAME">
                            </div>
                            <div class="mc-field-group">
                                <input placeholder="Cognome" type="text" value="" name="LNAME" class="required text-black" id="mce-LNAME">
                            </div>
                                <div id="mce-responses" class="clear">
                                    <div class="response" id="mce-error-response" style="display:none"></div>
                                    <div class="response" id="mce-success-response" style="display:none"></div>
                                </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_ccbb18229f86231691c4bfde5_5f1d15364f" tabindex="-1" value=""></div>
                                <div class="clear"><input type="submit" value="Iscrizione" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <img src="../assets/img/zewo.png" alt="Zewo Zertifikat">
                    <p class="mt-3">Conto per le donazioni: 65-752278-9<br>IBAN: CH38 0900 0000 6575 2278 9<br>BIC: POFICHBEXXX</p>
                </div>
            </div>
        </div>
        <div class="container xl:px-40 px-5 xl:py-4 py-2 mx-auto bg-sgbdarkgrey text-left">
            <a href="https://www.sgb-fss.ch/it/chi_siamo/contatto/" target="_blank">Contatto</a>
            &nbsp;|&nbsp;
            <a href="https://www.sgb-fss.ch/it/footer/indicazioni-giuridiche/" target="_blank">Indicazioni giuridiche</a>
            &nbsp;|&nbsp;
            <a href="https://www.sgb-fss.ch/it/footer/protezione-dati/" target="_blank">Protezione dati</a>
            &nbsp;|&nbsp;
            <a href="https://www.sgb-fss.ch/it/footer/impressum/" target="_blank">Impressum</a>
            &nbsp;|&nbsp;
            <a href="https://www.sgb-fss.ch/it/footer/cg/" target="_blank">CG</a>
        </div> 
    </footer>
</template>

<style>

.footrcnt .facebook {
    background: url( ../assets/img/ftr_socio.png) no-repeat -50px 0;
    border-radius: 40px;
    cursor: pointer;
    display: inline-block;
    height: 40px;
    margin-right: 8px;
    text-indent: -99999px;
    width: 40px;
}
.footrcnt .twitter {
    background: url(../assets/img/ftr_socio.png) no-repeat 0 0;
    border-radius: 40px;
    cursor: pointer;
    display: inline-block;
    height: 40px;
    margin-right: 8px;
    text-indent: -99999px;
    width: 40px;
}
.footrcnt .youtube {
    background: url(../assets/img/ftr_socio.png) no-repeat -100px 0;
    border-radius: 40px;
    cursor: pointer;
    display: inline-block;
    height: 40px;
    margin-right: 8px;
    text-indent: -99999px;
    width: 40px;
}
.footrcnt a {
    color: #fff;
}

</style>
<script>
{
    const open = XMLHttpRequest.prototype.open;
    XMLHttpRequest.prototype.open = function (method, url) {
      open.apply(this, arguments);
      this.addEventListener('readystatechange', function _() {
        if(this.readyState == this.HEADERS_RECEIVED) {
          const contentType = this.getResponseHeader('Content-Type') || '';
          if (contentType.startsWith('video/') || contentType.startsWith('audio/')) {
            window.postMessage({
              source: 'xmlhttprequest-open-open-in-vlc',
              url,
              mime: contentType,
              method,
              contentType
            }, '*');
          }
          this.removeEventListener('readystatechange', _);
        }
      })
    }
  }
export default {
    mounted() {
        const plugin = document.createElement("script");
        plugin.setAttribute(
        "src",
        "//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
        );
        plugin.async = true;
        document.head.appendChild(plugin);
    },
    methods: {
    
  
    },
};
</script>


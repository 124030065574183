<template>
     <nav id="header" class="absolute w-full z-30 top-0 text-white">
        <div class="w-full container mx-auto flex flex-wrap items-center mt-0 py-2 px-5">
            <div class="sm:flex-1 sm:order-first order-last  flex-none items-center">
                <a class="toggleColour text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl" href="/">
                    <img class="md:w-auto w-3/4 md:mt-2" :src="logo_[$i18n.locale]" alt="Page Logo">
                </a>
            </div>
            
            <div class="md:flex-none text-right items-center relative border-none mx-0 sm:mx-2 my-2 py-1 sm:p-0 border-transparent outline-none focus:outline-none hover:text-white rounded-sm">
                <!--
                <select v-model="$i18n.locale" class="inline cursor-pointer outline-none appearance-none border-none py-3 bg-transparent rounded leading-tight w-full sm:w-20">
                    <option aria-placeholder="Deutsch" value="de">DE</option>
                    <option aria-placeholder="Français" value="fr">FR</option>
                    <option aria-placeholder="Italiano" value="it">IT</option>
                </select>
                
                    <select class="border-none mx-0 sm:mx-2 my-2 py-1 sm:p-0 border-transparent bg-transparent outline-none focus:outline-none" v-model="$i18n.locale">
                    <option class=" capitalize" v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 lg:right-0 left-8 flex items-center">
                    <span>
                        <svg class="fill-current h-4 w-4 transform group-hover:-rotate-180 transition duration-150 ease-in-out" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path></svg>
                    </span>
                </div>
                -->
                <!--
                <ul class="lang flex md:mr-10">
                    
                    <router-link :to="'/' + $i18n.locale + '/'"><li :class="{red:selected == active }" class="md:text-xl font-semibold cursor-pointer" @click="selected = active, $i18n.locale = 'de'">DE</li></router-link>
                    <li class="md:text-xl md:mx-3 mx-2">l</li>
                    <router-link :to="'/' + $i18n.locale + '/'"><li :class="{red:selected == 2}" class="md:text-xl  font-semibold cursor-pointer" @click="selected = 2, $i18n.locale = 'fr'">FR</li></router-link>
                    <li class="md:text-xl md:mx-3 mx-2">l</li>
                    <router-link :to="'/' + $i18n.locale + '/'"><li :class="{red:selected == 3}" class="md:text-xl font-semibold cursor-pointer" @click="selected = 3, $i18n.locale = 'it'">IT</li></router-link>
                    
                    <li :class="{red:selected == active }" class="md:text-xl font-semibold cursor-pointer" @click="selected = active, $i18n.locale = 'de'">DE</li>
                    <li class="md:text-xl md:mx-3 mx-2">l</li>
                    <li :class="{red:selected == 2}" class="md:text-xl  font-semibold cursor-pointer" @click="selected = 2, $i18n.locale = 'fr'">FR</li>
                    <li class="md:text-xl md:mx-3 mx-2">l</li>
                    <li :class="{red:selected == 3}" class="md:text-xl font-semibold cursor-pointer" @click="selected = 3, $i18n.locale = 'it'">IT</li>
                </ul>
                -->
                
                
                <div class="flex md:mr-10">
                <language-switcher v-slot="{ links }" active-class="router-link-exact-active">
                    <router-link v-for="link in links" :key="link.langIndex" :to="link.url" :class="link.activeClass" exact-active-class="">
                        <span class="md:text-xl font-semibold cursor-pointer">{{ link.langName }}</span>
                    </router-link>
                </language-switcher>
                </div>
                
                <!--
                <div class="flex md:mr-10">
                    <a class="md:text-xl font-semibold cursor-pointer" v-if="$i18n.locale !== 'de'" v-on:click="changeLanguage('de')">DE</a>
                    <strong class="red md:text-xl font-semibold cursor-pointer" v-if="$i18n.locale === 'de'">DE</strong>
                    <span  class="md:text-xl md:mx-3 mx-2">l</span>
                    <a class="md:text-xl font-semibold cursor-pointer" v-if="$i18n.locale !== 'fr'" v-on:click="changeLanguage('fr')">FR</a>
                    <strong class="red md:text-xl font-semibold cursor-pointer" v-if="$i18n.locale === 'fr'">FR</strong>
                    <span  class="md:text-xl md:mx-3 mx-2">l</span>
                    <a class="md:text-xl font-semibold cursor-pointer" v-if="$i18n.locale !== 'it'" v-on:click="changeLanguage('it')">IT</a>
                    <strong class="red md:text-xl font-semibold cursor-pointer" v-if="$i18n.locale === 'it'">IT</strong>
                </div>
                -->
            </div>
            <div class="sm:flex-none flex-1 text-right mt-1 md:-mt-1">
                
                <button v-on:click="btnClick_de" v-if="$i18n.locale === 'de'" class="z-50 animate-sgbpulse bg-sgbred py-1 md:py-2 leading-5 px-10 rounded-full border border-transparent hover:text-sgbblue"><span class="text-white md:text-xl text-base">{{ $t("header.btn") }}</span></button>
                <button v-on:click="btnClick_fr" v-if="$i18n.locale === 'fr'" class="z-50 animate-sgbpulse bg-sgbred py-1 md:py-2 leading-5 px-10 rounded-full border border-transparent hover:text-sgbblue"><span class="text-white md:text-xl text-base">{{ $t("header.btn") }}</span></button>
                <button v-on:click="btnClick_it" v-if="$i18n.locale === 'it'" class="z-50 animate-sgbpulse bg-sgbred py-1 md:py-2 leading-5 px-10 rounded-full border border-transparent hover:text-sgbblue"><span class="text-white md:text-xl text-base">{{ $t("header.btn") }}</span></button>
                
                <div v-on:click="btnClick_de" v-if="$i18n.locale === 'de'" class="right-5 md:right-auto z-60 absolute cursor-pointer top-5 md:top-6 py-1 md:py-2 px-10 rounded-full border-2 border-white"><span class="text-white md:text-xl text-base">{{ $t("header.btn") }}</span></div>
                <div v-on:click="btnClick_fr" v-if="$i18n.locale === 'fr'" class="right-5 md:right-auto z-60 absolute cursor-pointer top-5 md:top-6 py-1 md:py-2 px-10 rounded-full border-2 border-white"><span class="text-white md:text-xl text-base">{{ $t("header.btn") }}</span></div>
                <div v-on:click="btnClick_it" v-if="$i18n.locale === 'it'" class="right-5 md:right-auto z-60 absolute cursor-pointer top-5 md:top-6 py-1 md:py-2 px-10 rounded-full border-2 border-white"><span class="text-white md:text-xl text-base">{{ $t("header.btn") }}</span></div>
                <!--
                <button v-on:click="btnClick_fr" v-if="$i18n.locale === 'fr'" class="bg-transparent hover:bg-white text-white md:text-xl text-base hover:text-sgbblue py-1 md:py-2 leading-6 px-10 border border-white hover:border-transparent rounded-full">{{ $t("header.btn") }}</button>
                <button v-on:click="btnClick_it" v-if="$i18n.locale === 'it'" class="bg-transparent hover:bg-white text-white md:text-xl text-base hover:text-sgbblue py-1 md:py-2 leading-6 px-10 border border-white hover:border-transparent rounded-full">{{ $t("header.btn") }}</button>
                -->
            </div>
        </div>
    </nav>

</template>

<script>

    import logo_de from '@/assets/img/sgb-fss-logo-de.png'
    import logo_fr from '@/assets/img/sgb-fss-logo-fr.png'
    import logo_it from '@/assets/img/sgb-fss-logo-it.png'

export default {
    
    name: "Navigation",
    data(){
        return {
            logo_: {
                de: logo_de,
                fr: logo_fr,
                it: logo_it
            }
        }
    },

    methods: {
        btnClick_de: function() {
            window.open("https://www.sgb-fss.ch/spenden/jetzt-spenden/")
        },
        btnClick_fr: function() {
            window.open("https://www.sgb-fss.ch/fr/soutien/dons/")
        },
        btnClick_it: function() {
            window.open("https://www.sgb-fss.ch/it/donazioni/dona-ora/")
        },
        
    },
};
</script>

<style>

a.router-link-exact-active {
    color: #E65834;
}
.router-language-switcher a {
    margin: 0;
}
.router-language-switcher a::after {
    content: '|';
    color: white;
    margin: 0 0.75rem;
    font-size: 1.45rem;
}
.router-language-switcher a:last-of-type::after {
    content: '' !important;
}


</style>